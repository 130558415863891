import React from "react";
import PostSubline from "./PostSubline";
import { View } from "@rk/design-system";
import styled from "styled-components";

export const PostTitle = styled.h2`
  position: relative;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  margin-bottom: 0.75rem;
  font-size: 2.5rem;
`;

export const PostTitleInitiale = styled.span`
  position: absolute;
  font-size: 7rem;
  transform: translate(-50%, -50%);
  opacity: 0.08;
  user-select: none;
`;

const ArticleExcerpt = ({
  id,
  title,
  date,
  excerpt,
  category,
  slug,
  disqus,
  url,
  timeToRead,
  articleLinkHandler,
  categoryLinkHandler
}) => {
  const disqusConfig = {
    url: url,
    identifier: id,
    title: title
  };

  return (
    <View my="4rem">
      <PostTitle>
        <PostTitleInitiale>{title.charAt(0)}</PostTitleInitiale>
        <a href="#" onClick={() => articleLinkHandler(slug)}>
          {title}
        </a>
      </PostTitle>
      <PostSubline
        date={date}
        disqus={disqus}
        config={disqusConfig}
        timeToRead={timeToRead}
        category={category}
        categoryLinkHandler={categoryLinkHandler}
      />
      <View my="1rem">{excerpt}</View>
    </View>
  );
};

export default ArticleExcerpt;

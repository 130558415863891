import React from "react";
import ArticleExcerpt from "./ArticleExcerpt";
import PrevNext from "./PrevNext";

const Articles = ({
  metadata,
  postEdges,
  prev,
  next,
  articleLinkHandler,
  categoryLinkHandler,
  prevLinkHandler,
  nextLinkHandler
}) => (
  <div>
    {postEdges.map(post => (
      <ArticleExcerpt
        id={post.id}
        title={post.node.frontmatter.title}
        date={post.node.frontmatter.date}
        category={post.node.frontmatter.category}
        excerpt={post.node.excerpt}
        slug={post.node.fields.slug}
        key={post.node.fields.slug}
        url={`${metadata.url}${post.node.fields.slug}`}
        disqus={metadata.disqus}
        timeToRead={post.node.timeToRead}
        articleLinkHandler={articleLinkHandler}
        categoryLinkHandler={categoryLinkHandler}
      />
    ))}
    <PrevNext
      prev={prev}
      prevText="Newer Posts"
      prevLinkHandler={prevLinkHandler}
      next={next}
      nextText="Older Posts"
      nextLinkHandler={nextLinkHandler}
    />
  </div>
);

export default Articles;

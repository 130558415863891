import React from "react";
import { graphql, navigate } from "gatsby";
import kebabCase from "lodash/kebabCase";
import { Page, Articles } from "./../components";

const Category = ({
  data: {
    site: { siteMetadata: metadata },
    allMarkdownRemark: { totalCount, edges: postEdges }
  },
  pageContext
}) => {
  const subline = `${totalCount} post${
    totalCount === 1 ? "" : "s"
    } tagged with "${pageContext.category}"`;

  return (
    <Page
      title={"Category: " + pageContext.category}
      header={<>Category &ndash; {pageContext.category}</>}
      subheader={
        <>
          {subline} (See{" "}
          <a href="#" onClick={() => navigate("/categories")}>
            All categories
          </a>
          )
        </>
      }
      metadata={metadata}
      titleLinkHandler={() => navigate("/")}
    >
      <Articles
        postEdges={postEdges}
        metadata={metadata}
        next={pageContext.nextPage}
        prev={pageContext.previousPage}
        articleLinkHandler={arg => navigate(arg)}
        categoryLinkHandler={arg => navigate(`/categories/${kebabCase(arg)}`)}
        prevLinkHandler={arg => navigate(arg)}
        nextLinkHandler={arg => navigate(arg)}
      />
    </Page>
  );
};

export default Category;

export const pageQuery = graphql`
  query CategoryPage($category: String!, $limit: Int, $skip: Int) {
    site {
      siteMetadata {
        title
        description
        year
        disqus

        title
        description
        year
        disqus
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
          }
          fields {
            slug
          }
          excerpt(pruneLength: 260)
          timeToRead
        }
      }
    }
  }
`;

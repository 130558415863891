import React from "react";
import { DiscussionEmbed } from "disqus-react";
import PostSubline from "./PostSubline";
import { View } from "@rk/design-system";
import styled from "styled-components";

export const PostLargeTitle = styled.h1`
  font-size: 3rem;
`;

const Article = ({
  id,
  title,
  url,
  date,
  html,
  category,
  disqus,
  timeToRead,
  categoryLinkHandler
}) => {
  const disqusConfig = {
    url: url,
    identifier: id,
    title: title
  };

  return (
    <View as="article">
      <PostLargeTitle>{title}</PostLargeTitle>
      <PostSubline
        date={date}
        disqus={disqus}
        config={disqusConfig}
        timeToRead={timeToRead}
        category={category}
        categoryLinkHandler={categoryLinkHandler}
      />
      <View mt="2rem" dangerouslySetInnerHTML={{ __html: html }} />
      <DiscussionEmbed shortname={disqus} config={disqusConfig} />
    </View>
  );
};

export default Article;

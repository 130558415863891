import React from "react";
import { graphql, navigate } from "gatsby";
import kebabCase from "lodash/kebabCase";
import { Page, Article } from "./../components";

const PostPage = ({
  data: {
    site: { siteMetadata: metadata },
    markdownRemark: post
  }
}) => {
  return (
    <Page
      title={post.frontmatter.title}
      metadata={metadata}
      titleLinkHandler={() => navigate("/")}
    >
      <Article
        id={post.id}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        category={post.frontmatter.category}
        html={post.html}
        url={`${metadata.url}${post.fields.slug}`}
        disqus={metadata.disqus}
        timeToRead={post.timeToRead}
        categoryLinkHandler={arg => navigate(`/categories/${kebabCase(arg)}`)}
      />
    </Page>
  );
};

export default PostPage;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        year
        url
        disqus
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        category
      }
    }
  }
`;

import React from "react";

const useLocalStorage = (localStorageKey, defaultValue) => {
  const isBrowser = () => typeof window !== "undefined";
  const [value, setValue] = React.useState(
    (isBrowser() && localStorage.getItem(localStorageKey)) || defaultValue
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

export default useLocalStorage;

import React from "react";
import { graphql, navigate } from "gatsby";
import kebabCase from "lodash/kebabCase";
import { Page, Articles } from "./../components";

const BlogPage = ({
  data: {
    site: { siteMetadata: metadata },
    allMarkdownRemark: { edges: postEdges }
  },
  pageContext
}) => {
  return (
    <Page
      title="Latest Stories"
      header="Latest Stories"
      metadata={metadata}
      titleLinkHandler={() => navigate("/")}
    >
      <Articles
        postEdges={postEdges}
        metadata={metadata}
        next={pageContext.nextPage}
        prev={pageContext.previousPage}
        articleLinkHandler={arg => navigate(arg)}
        categoryLinkHandler={arg => navigate(`/categories/${kebabCase(arg)}`)}
        prevLinkHandler={arg => navigate(arg)}
        nextLinkHandler={arg => navigate(arg)}
      />
    </Page>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogPosts($limit: Int, $skip: Int) {
    site {
      siteMetadata {
        title
        description
        year
        disqus
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
          }
        }
      }
    }
  }
`;

import React from "react";
import kebabCase from "lodash/kebabCase";
import { graphql, navigate } from "gatsby";
import { Page } from "./../components";

const CategoriesPage = ({
  data: {
    site: { siteMetadata: metadata },
    allMarkdownRemark: { group }
  }
}) => (
    <Page
      title="Categories"
      header="Categories"
      metadata={metadata}
      titleLinkHandler={() => navigate("/")}
    >
      {group.map(category => (
        <h2 key={category.fieldValue}>
          <a
            href="#"
            onClick={() =>
              navigate(`/categories/${kebabCase(category.fieldValue)}`)
            }
          >
            {category.fieldValue}
          </a>{" "}
        ({category.totalCount})
        </h2>
      ))}
    </Page>
  );

export default CategoriesPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        year
        disqus
      }
    }
    allMarkdownRemark {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";

const Subline = styled.div`
  font-size: 1rem;
  color: ${props => props.theme.colors.textColor};
`;

const PostSubline = ({ date, timeToRead, category, categoryLinkHandler }) => {
  return (
    <Subline>
      {date} &mdash; {timeToRead} Min Read &mdash; In{" "}
      <React.Fragment key={category}>
        <a href="#" onClick={() => categoryLinkHandler(category)}>
          {category}
        </a>
      </React.Fragment>
    </Subline>
  );
};

export default PostSubline;

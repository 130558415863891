module.exports = {
  breakpoints: [576, 768, 992, 1200],
  fonts: {
    sansSerif: `"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif`,
    serif: `"Bitter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica", "Arial", serif`
  },
  light: {
    colors: {
      primary: "#744c9b",
      textAccent: "#ffffff",
      primaryAccent: "#744c9b",
      secondary: "#e9ecef",
      background: "#ffffff",
      textColor: "#212529"
    }
  },
  dark: {
    colors: {
      primary: "#744c9b",
      textAccent: "#ffffff",
      primaryAccent: "#222",
      secondary: "#222",
      background: "#000",
      textColor: "#fff"
    }
  }
};

import React from "react";
import { graphql, navigate } from "gatsby";
import { Page } from "./../components";

const TermsPage = ({
  data: {
    site: { siteMetadata: metadata }
  }
}) => (
  <Page
    title="Terms and Conditions"
    header="Terms & Conditions"
    metadata={metadata}
    titleLinkHandler={() => navigate("/")}
  >
    <p>DataModeler is free to use with some feature limitations. You may remove those limitations by purchasing DataModeler Premium.</p>

    <p>DataModeler Premium is enabled via auto-renewing subscriptions.</p>

    <p>For the monthly subscription, your iTunes account will be charged at confirmation of purchase.</p>

    <p>Subscriptions automatically renew unless auto-renew is turned off at least 24h before the current period ends.</p>

    <p>The duration and price of each subscription is displayed on the purchase screen, and updated at the time of purchase.</p>

    <p>To manage your subscriptions or turn off auto-renew, please go to your iTunes account settings.</p>

    <p>If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to aarkaystudio@outlook.com</p>
    <p>This document was last updated on August 18, 2021</p>
  </Page>
);

export default TermsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        year
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";
import { darken } from "polished";

const PrevNextContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
`;

const Prev = styled.div`
  a {
    color: ${props => props.theme.colors.primary};
  }
  background-color: ${props => props.theme.colors.secondary};
  padding: 1.5rem;
  float: left;
  :hover:not(.disabled) {
    background-color: ${props => props.theme.colors.primary};
    a {
      color: white !important;
    }
  }
`;

const Next = styled.div`
  a {
    color: ${props => props.theme.colors.primary};
  }
  background-color: ${props => darken(0.05, props.theme.colors.secondary)};
  padding: 1.5rem;
  float: right;
  :hover:not(.disabled) {
    background-color: ${props => props.theme.colors.primary};
    a {
      color: white !important;
    }
  }
`;

const PrevNext = ({
  prev,
  prevText,
  prevLinkHandler,
  next,
  nextText,
  nextLinkHandler
}) => {
  return (
    <>
      {(prev || next) && (
        <PrevNextContainer className="row ">
          <Prev
            className={
              prev === null
                ? "col-6 rounded-left disabled"
                : "col-6 rounded-left"
            }
          >
            {prev && (
              <>
                <a href="#" onClick={() => prevLinkHandler(prev)}>
                  {prevText}
                </a>
              </>
            )}
          </Prev>
          <Next
            className={
              next === null
                ? "col-6 rounded-right disabled"
                : "col-6 rounded-right"
            }
          >
            {next && (
              <>
                <a href="#" onClick={() => nextLinkHandler(next)}>
                  {nextText}
                </a>
              </>
            )}
          </Next>
        </PrevNextContainer>
      )}
    </>
  );
};

export default PrevNext;

import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "@rk/design-system";
import { ThemeSwitch } from "./../components";
import theme from "./../../config/theme";
import { useLocalStorage } from "./../hocs";

const Root = ({ children }) => {
  const [themeMode, setThemeMode] = useLocalStorage("themeMode", "light");
  const globaltheme = {
    ...theme,
    colors: {
      ...theme[themeMode].colors
    }
  };
  return (
    <ThemeProvider theme={globaltheme}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  );
};

export default Root;

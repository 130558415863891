import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFileAlt,
  faFolder,
  faIdCardAlt,
  faChalkboardTeacher,
  faBlog,
  faGraduationCap,
  faEnvelope,
  faRocket,
  faMoon,
  faSun
} from "@fortawesome/free-solid-svg-icons";
import {
  faReact,
  faTwitter,
  faGithub,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(
  faFileAlt,
  faFolder,
  faSun,
  faMoon,
  faRocket,
  faEnvelope,
  faIdCardAlt,
  faChalkboardTeacher,
  faBlog,
  faBlog,
  faGraduationCap,
  faReact,
  faTwitter,
  faGithub,
  faLinkedin
);

const icons = {
  dark: "moon",
  light: "sun",
  job: "id-card-alt",
  conference: "chalkboard-teacher",
  education: "graduation-cap",
  email: "envelope",
  react: ["fab", "react"],
  oss: ["fab", "github"],
  github: ["fab", "github"],
  twitter: ["fab", "twitter"],
  linkedin: ["fab", "linkedin"]
};

export const Icon = ({ style, name, size }) => {
  return (
    <FontAwesomeIcon icon={icons[name] || name} size={size} style={style} />
  );
};

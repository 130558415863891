import React from "react";
import Helmet from "react-helmet";
import Main from "./Main";

const Page = ({
  title,
  header,
  subheader,
  metadata,
  children
}) => {
  return (
    <>
      {title && <Helmet title={`${title} | ${metadata.title}`} />}
      <Main
        metadata={metadata}
        header={header}
        subheader={subheader}
        children={children}
      />
    </>
  );
};

export default Page;

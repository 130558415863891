import React from "react";
import { View } from "@rk/design-system";
import styled from "styled-components";

const Main = ({ header, subheader, metadata, children }) => {
  return (
    <>
      <View px={["2.5rem", "2.5rem", "5rem"]} py={"2.5rem"}>
        {(header || subheader) && (
          <View mb={"4rem"} textAlign="center">
            {header && (
              <PageSectionTitle pb={"0.5rem"}>{header}</PageSectionTitle>
            )}
            {subheader && <View mt={"0.5rem"}>{subheader}</View>}
          </View>
        )}
        {children}
      </View>
      <View
        py={"2.5rem"}
        textAlign="center"
        css={`
          background: ${props => props.theme.colors.secondary};
        `}
      >
        &copy; {metadata.year} by {metadata.title}
        . All rights reserved. <br />
      </View>
    </>
  );
};

export default Main;

const PageSectionTitle = styled(View)`
  position: relative;
  text-transform: uppercase;
  font-weight: bold;

  &:after {
    content: "";
    height: 1px;
    width: 50px;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
    background: ${props => props.theme.colors.secondary};
  }
`;
